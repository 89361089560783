<template>
  <div class="login-container">
    <div class="col-12" style="text-align:center">
      <img v-if="logo" :src="logo" alt="Giftolife" class="sidebar-logo" style="width: 180px;margin: 150px 0px 0px 0px;">
    </div>
    <el-form v-if="!hasCode && !codIsValid" ref="mailForm" :model="mailForm" :rules="mailRules" class="login-form" auto-complete="on" label-position="left">
      <h3 class="title">
        {{ $t('passwordreset.enterEmail') }}
      </h3>
      <el-form-item prop="email">
        <span class="svg-container">
          <i class="el-icon-mail" />
        </span>
        <el-input v-model="mailForm.email" name="email" type="email" auto-complete="on" :placeholder="$t('login.email')" />
      </el-form-item>
      <el-form-item>
        <el-button :loading="emailloading" type="primary" style="width:100%;" @click.native.prevent="handleSendToken">
          {{ $t('passwordreset.sendcode') }}
        </el-button>
      </el-form-item>
      <div class="tips" style="text-align:center">
        <router-link to="login"><span> {{ $t('login.title') }} </span></router-link>
      </div>
    </el-form>
    <el-form v-if="hasCode && !codIsValid" ref="tokenForm" :model="tokenForm" :rules="tokenRules" class="login-form" auto-complete="on" label-position="left">
      <h3 class="title">
        {{ $t('passwordreset.enterCode') }}
      </h3>
      <el-form-item prop="token">
        <span class="svg-container">
          <i class="el-icon-user" />
        </span>
        <el-input v-model="tokenForm.token" name="token" type="text" auto-complete="on" :placeholder="$t('passwordreset.token')" />
      </el-form-item>
      <el-form-item>
        <el-button :loading="tokenLoading" type="primary" style="width:100%;" @click.native.prevent="handleCheckToken">
          {{ $t('passwordreset.checkCode') }}
        </el-button>
      </el-form-item>
      <div class="tips" style="text-align:center">
        <a href="reset-password" @click="tokenForm.token=''"><span> {{ $t('passwordreset.resendcode') }} </span></a>
      </div>
    </el-form>
    <el-form v-if="hasCode && codIsValid && !passwordChangeSuccess" ref="passwordForm" :model="passwordForm" :rules="passwordRules" class="login-form" auto-complete="on" label-position="left">
      <h3 class="title">
        {{ $t('passwordreset.setYourPassword') }}
      </h3>

      <el-form-item prop="password">
        <span class="svg-container">
          <i class="el-icon-lock" />
        </span>
        <el-input
          v-model="passwordForm.password"
          :type="pwdType"
          name="password"
          auto-complete="on"
          :placeholder="$t('passwordreset.password')"
        />
        <span class="show-pwd" @click="showPwd">
          <i class="el-icon-view" />
        </span>
      </el-form-item>

      <el-form-item prop="confirmPassword">
        <span class="svg-container">
          <i class="el-icon-lock" />
        </span>
        <el-input
          v-model="passwordForm.confirmPassword"
          type="password"
          name="confirmPassword"
          auto-complete="on"
          :placeholder="$t('passwordreset.confirmPassword')"
          @keyup.enter.native="handleSetPassword"
        />
      </el-form-item>

      <el-form-item>
        <el-button :loading="passwordLoading" type="primary" style="width:100%;" @click.native.prevent="handleSetPassword">
          {{ $t('passwordreset.savepassword') }}
        </el-button>
      </el-form-item>
      <div class="tips" style="text-align:center">
        <router-link to="login"><span> {{ $t('login.title') }} </span></router-link>
      </div>
    </el-form>

    <div v-if="hasCode && codIsValid && passwordChangeSuccess" class="password-changed-successfully min-height-v-50 z-index-0">
      <el-form class="pswd-success-card">
        <div class="text-center successful-operation">
          <div class="sa">
            <div class="sa-success">
              <div class="sa-success-tip" />
              <div class="sa-success-long" />
              <div class="sa-success-placeholder" />
              <div class="sa-success-fix" />
            </div>
          </div>
          <h4 class="color-white">{{ $t("passwordreset.successEnd") }}</h4>
          <p class="color-white">{{ $t("passwordreset.successEndDetails") }}</p>
          <router-link to="login" type="primary" class="color-white bg-gradient-default btn-md btn-round me-1">
            <i class="fas fa-arrow-left text-sm ms-1" aria-hidden="true" />
            {{ $t("login.title") }}
          </router-link>
        </div>
      </el-form>
    </div>

  </div>
</template>

<script>
import defaultSettings from '@/settings';
import { validEmail } from '@/utils/validate';
import { csrf } from '@/api/auth';
import Resource from '@/api/resource';
const sendCodeResource = new Resource('forgot-password');
const checkCodeResource = new Resource('verify/pin');
const resetPasswordResource = new Resource('reset-password');

export default {
  name: 'ResetPassword',
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!validEmail(value)) {
        callback(new Error(this.$t('login.correctEmail')));
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error(this.$t('login.shortPassword')));
      } else {
        callback();
      }
    };
    const validateConfirmPassword = (rule, value, callback) => {
      if (value !== this.passwordForm.password) {
        callback(new Error(this.$t('user.PasswordsInconform')));
      } else {
        callback();
      }
    };
    return {
      logo: defaultSettings.appbaseUrl.includes('beta') ? '/images/logo_beta.png' : '/images/logo.png',
      passwordChangeSuccess: false,
      hasCode: false,
      codIsValid: false,
      emailloading: false,
      passwordLoading: false,
      tokenForm: {
        token: '',
        email: '',
      },
      mailForm: {
        email: '',
      },
      passwordForm: {
        password: '',
        confirmPassword: '',
      },
      tokenRules: {
        token: [{ required: true, trigger: 'blur', message: this.$t('passwordreset.codeIsRequired') }],
      },
      mailRules: {
        email: [{ required: true, trigger: 'blur', validator: validateEmail }],
      },
      passwordRules: {
        password: [
          {
            required: true,
            trigger: 'blur',
            validator: validatePass,
          },
        ],
        confirmPassword: [
          { validator: validateConfirmPassword, trigger: 'blur', message: this.$t('user.PasswordsInconform') },
        ],
      },
      loading: false,
      tokenLoading: false,
      pwdType: 'password',
      redirect: undefined,
      otherQuery: {},
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true,
    },
  },
  async created() {
    const token = this.$route.query.t;
    if (token !== undefined && token !== ''){
      const mail = this.$route.query.m;
      this.tokenForm.token = token;
      this.mailForm.email = mail;
      this.hasCode = true;
    }
  },
  methods: {
    showPwd() {
      if (this.pwdType === 'password') {
        this.pwdType = '';
      } else {
        this.pwdType = 'password';
      }
    },
    async handleSetPassword(){
      await this.$refs.passwordForm.validate(valid => {
        if (valid) {
          this.passwordLoading = true;
          const queryData = {
            email: this.mailForm.email,
            password: this.passwordForm.password,
            password_confirmation: this.passwordForm.confirmPassword,
          };
          csrf().then(() => {
            resetPasswordResource
              .store(queryData)
              .then(async(response) => {
                this.$message({
                  type: 'success',
                  message: this.$t('passwordreset.successEnd'),
                });
                this.$nextTick(() => {
                  this.$refs.passwordForm.clearValidate();
                });
                this.passwordChangeSuccess = true;
              })
              .catch(error => {
                console.log(error);
              })
              .finally(() => {
                this.passwordLoading = false;
              });
          });
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
          return false;
        }
      });
    },
    async handleCheckToken(){
      await this.$refs.tokenForm.validate(valid => {
        if (valid) {
          this.tokenLoading = true;
          this.tokenForm.email = this.mailForm.email;
          csrf().then(() => {
            checkCodeResource
              .store(this.tokenForm)
              .then(async(response) => {
                this.$message({
                  type: 'success',
                  message: this.$t('passwordreset.codeIsValid'),
                });
                this.$nextTick(() => {
                  this.$refs.tokenForm.clearValidate();
                });
                this.codIsValid = true;
              })
              .catch(error => {
                console.log(error);
              })
              .finally(() => {
                this.tokenLoading = false;
              });
          });
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
          return false;
        }
      });
    },
    async handleSendToken(){
      this.$refs.mailForm.validate(valid => {
        if (valid) {
          this.emailloading = true;
          csrf().then(() => {
            sendCodeResource
              .store(this.mailForm)
              .then(async(response) => {
                this.$message({
                  type: 'success',
                  message: this.$t('passwordreset.codeSent'),
                });
                this.$nextTick(() => {
                  this.$refs.mailForm.clearValidate();
                });
                this.hasCode = true;
              })
              .catch(error => {
                console.log(error);
              })
              .finally(() => {
                this.emailloading = false;
              });
          });
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
          return false;
        }
      });
    },
    async handleLogin() {
      await this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true;
          csrf().then(() => {
            this.$store.dispatch('user/login', this.loginForm)
              .then(() => {
                this.$router.push({ path: this.redirect || '/', query: this.otherQuery }, onAbort => {});
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          });
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
          return false;
        }
      });
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
$bg: #2d3a4b;
$light_gray: #eee;

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;
    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: #fff !important;
      }
    }
  }
  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
.login-container {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: $bg;
  .login-form {
    position: absolute;
    left: 0;
    right: 0;
    width: 520px;
    max-width: 100%;
    padding: 35px 35px 15px 35px;
    margin: 120px auto;
    margin-top: 5px !important;
  }
  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;
    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }
  .title {
    font-size: 23px;
    color: $light_gray;
    margin: 0px auto 10px auto;
    text-align: center;
    font-weight: bold;
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
  .set-language {
    color: #fff;
    position: absolute;
    top: 40px;
    right: 35px;
  }
}
@media screen and (orientation: landscape) and (max-width: 1024px) {
  .login-container {
    position: relative;
    overflow-y: auto;
    .login-form {
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      margin: auto;
    }
  }
}
</style>
